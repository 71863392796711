import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'
// import document from '@/common/webClick'

import TreeTable from 'vue-table-with-tree-grid'

// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入全局样式表
import './assets/css/global.css'

// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'

// 导入NProgress 对应的js和css
import NProgress from 'nprogress'

import axios from 'axios'
// 在request拦截器中展示进度条 NProgress.start()
axios.defaults.baseURL = 'https://todo.1tomall.com/api/'
axios.interceptors.request.use(config => {
  NProgress.start()
  config.validateStatus = function(status) {
    return status >= 200 && status < 500
  }
  // 登录授权 请求验证是否有token  需要授权的 API ，必须在请求头中使用 `Authorization` 字段提供 `token` 令牌
  config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('token')
  return config // 必须返回否则没有值
})
// 在reponse拦截器中,隐藏进度条 NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.component('tree-table', TreeTable)
// 将富文本编辑器注册为全局组件
Vue.use(VueQuillEditor)
// 全局时间过滤器
Vue.filter('dataFormat', function (originVal) {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  // yyyy-mm-dd hh:mm:ss
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
Vue.filter('dateToEnd', function (val) {
  const dateNum = val
  if (val * 1 > 0) {
    return val + '天后'
  } else if (val * 1 === 0) {
    return '今天'
  } else {
    return '过期了' + val * -1 + '天'
  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
