import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import(/* webpackChunkName: "login_home_welome" */ 'components/login/Login')
const Home = () => import(/* webpackChunkName: "login_home_welome" */ 'components/home/Home')
const Welcome = () => import(/* webpackChunkName: "login_home_welome" */ 'components/home/welcome/Welcome')

const Taskdetail = () => import(/* webpackChunkName: "login_home_welome" */ 'components/home/task/Taskdetail')

const Users = () => import(/* webpackChunkName: "Users_Rights_Roles" */ 'components/home/users/Users')
const Score = () => import(/* webpackChunkName: "Users_Rights_Roles" */ 'components/home/score/Score')
// const Rights = () => import(/* webpackChunkName: "Users_Rights_Roles" */ 'components/home/power/rights/Rights')
// const Roles = () => import(/* webpackChunkName: "Users_Rights_Roles" */ 'components/home/power/roles/Roles')

// const Cate = () => import(/* webpackChunkName: "Cate_Params" */ 'components/home/goods/cate/Cate')
// const Params = () => import(/* webpackChunkName: "Cate_Params" */ 'components/home/goods/params/Params')

// const GoodsList = () => import(/* webpackChunkName: "GoodsList_Add" */ 'components/home/goods/list/List')
const Add = () => import(/* webpackChunkName: "GoodsList_Add" */ 'components/home/task/Add')

// const Order = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/order/Order')
// const Report = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/report/Report')
const Companions = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/users/Companions')
const Department = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/department/Department')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        component: Welcome
      },
      {
        path: '/score',
        component: Score
      },
      {
        path: '/task',
        component: Taskdetail
      },
      {
        path: '/users',
        component: Users
      },
      {
        path: '/companions',
        component: Companions
      },
      {
        path: '/department',
        component: Department
      },
      {
        path: '/taskAdd',
        component: Add
      }
      // {
      //   path: '/rights',
      //   component: Rights
      // },
      // {
      //   path: '/roles',
      //   component: Roles
      // },
      // {
      //   path: '/categories',
      //   component: Cate
      // },
      // {
      //   path: '/params',
      //   component: Params
      // },
      // {
      //   path: '/goods',
      //   component: GoodsList
      // },
      // {
      //   path: '/goods/add',
      //   component: Add
      // },
      // {
      //   path: '/orders',
      //   component: Order
      // },
      // {
      //   path: '/reports',
      //   component: Report
      // },
    ]
  }
]
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // to 将访问哪一个路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数,表示放行
  //   next() 放行 next('/login') 强制跳转
  if (to.path === '/login') return next()
  // 获取token
  const token = window.sessionStorage.getItem('token')
  if (!token) return next('/login')
  next()
})

export default router
